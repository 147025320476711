/* eslint-disable */
// CalculateUtils.js

// 1) Basit seedli (tohumlu) rastgele sayı üreteci ayarları.
export const INITIAL_SEED = 5000;
let currentSeededRandom = null;

/**
 * Linear Congruential Generator (LCG) tabanlı basit bir "seedli" random fonksiyonu üretir.
 * Aynı seed ile hep aynı sıralamada [0..1) sayıları döndürür.
 */
function seededRandomFactory(seed) {
  const m = 0x80000000; // 2^31
  const a = 1103515245;
  const c = 12345;

  let state = (seed === undefined) ? 123456789 : seed;

  return function() {
    state = (a * state + c) % m;
    return state / (m - 1);
  };
}

/**
 * Rastgele üreteci INITIAL_SEED değeriyle baştan başlatır.
 */
export function resetSeed(){
  currentSeededRandom = seededRandomFactory(INITIAL_SEED);
}

/**
 * [0..n) aralığında tam sayı döndürür. currentSeededRandom yoksa önce resetSeed().
 */
export function randomInt(n) {
  if (!currentSeededRandom) {
    resetSeed();
  }
  return Math.floor(currentSeededRandom() * n);
}

// ----------------------------------------------------------------------------
// 2) Vektör işlemleri
// ----------------------------------------------------------------------------

/** 
 * Bir vektörün Öklid normunu (uzunluğunu) döndürür. 
 * norm = sqrt(x1^2 + x2^2 + ...)
 */
export function vectorNorm(vec) {
  let sumSq = 0;
  for (let i = 0; i < vec.length; i++) {
    sumSq += vec[i] * vec[i];
  }
  return Math.sqrt(sumSq);
}

/** İki vektörün bileşen bazında toplanması */
export function vectorAdd(a, b) {
  let result = new Array(a.length);
  for (let i = 0; i < a.length; i++) {
    result[i] = a[i] + b[i];
  }
  return result;
}

/** İki vektörün bileşen bazında çıkarılması */
export function vectorSub(a, b) {
  let result = new Array(a.length);
  for (let i = 0; i < a.length; i++) {
    result[i] = a[i] - b[i];
  }
  return result;
}

/** Verilen uzunlukta sıfırlarla dolu bir vektör döndürür. */
export function zeroVector(length) {
  let arr = new Array(length);
  for (let i = 0; i < length; i++) {
    arr[i] = 0;
  }
  return arr;
}

// ----------------------------------------------------------------------------
// 3) CansFastMonteCarlo (fastMonteCarlo.py eşdeğeri)
// ----------------------------------------------------------------------------

/**
 * CansFastMonteCarlo
 * subtractedData: Her satır bir vektör (25 boyutlu veya 26 boyutlu).
 * maxNumBestIndexes, earlyBreakLife: parametreler
 * seed_: Python kodundan geliyor, biz randomInt kullanıyoruz.
 *
 * Dönüş: [bestDistance, model]
 * model => [ [index, frequency], ...]
 */
export function CansFastMonteCarlo(subtractedData, maxNumBestIndexes, earlyBreakLife, seed_) {
  let dataLength = subtractedData.length;
  if (dataLength === 0) {
    return [9999, []];
  }
  let vectorDim = subtractedData[0].length; 

  // 0) Rastgele bestIndexes seç
  let bestIndexes = [];
  for(let i = 0; i < maxNumBestIndexes; i++){
    bestIndexes.push(randomInt(dataLength));
  }
  let indexHistogram = new Array(dataLength).fill(0);

  // bestDiffSum = seçilen indexlerin vektörel toplamı
  let bestDiffSum = zeroVector(vectorDim);
  for(let idx of bestIndexes){
    bestDiffSum = vectorAdd(bestDiffSum, subtractedData[idx]);
    indexHistogram[idx]++;
  }

  // 1) nCycle
  let nCycle = dataLength;
  let earlyBreak = false;
  let replacementDict = {};
  let previousBestIndex = bestIndexes[0];

  for (let cyc = 0; cyc < nCycle; cyc++) {
    if (earlyBreak) {
      earlyBreakLife -= 1;
      if (earlyBreakLife === 0) {
        break;
      }
    }
    earlyBreak = true;

    for (let i = 0; i < maxNumBestIndexes; i++) {
      let bestDiffSumWithoutCurrent = vectorSub(bestDiffSum, subtractedData[bestIndexes[i]]);
      let possibleNewIndex = replacementDict[bestIndexes[i]];
      if (possibleNewIndex === undefined) {
        possibleNewIndex = previousBestIndex;
      }

      let diffSum = vectorAdd(bestDiffSumWithoutCurrent, subtractedData[possibleNewIndex]);
      let betterIndexFound = false;

      // 20 deneme
      for (let t = 0; t < 20; t++) {
        if (vectorNorm(diffSum) < vectorNorm(bestDiffSum)) {
          betterIndexFound = true;
          break;
        }
        possibleNewIndex = randomInt(dataLength);
        diffSum = vectorAdd(bestDiffSumWithoutCurrent, subtractedData[possibleNewIndex]);
      }

      if (betterIndexFound) {
        if (bestIndexes[i] !== possibleNewIndex) {
          replacementDict[bestIndexes[i]] = possibleNewIndex;
        }
        indexHistogram[bestIndexes[i]] -= 1;
        indexHistogram[possibleNewIndex] += 1;

        bestDiffSum = diffSum;
        bestIndexes[i] = possibleNewIndex;
        earlyBreak = false;
      }
      previousBestIndex = bestIndexes[i];
    }
  }

  // 5) model hazırlama
  let model = [];
  for(let index = 0; index < dataLength; index++){
    if(indexHistogram[index] > 0){
      model.push([index, indexHistogram[index]]);
    }
  }
  model.sort((a,b) => b[1] - a[1]);

  // 6) Az kullanılan indexleri çok kullanılanlarla değiştirme
  let betterIndexOnceFound = true;
  while(betterIndexOnceFound){
    betterIndexOnceFound = false;
    for(let i = model.length - 1; i >= 0; i--){
      for(let j = 0; j < model.length; j++){
        if(i === j || model[i][1] === 0) continue;
        let worseIndex = model[i][0];
        let betterIndex = model[j][0];
        let localBetterIndexFound = true;
        while(localBetterIndexFound){
          localBetterIndexFound = false;
          let newDiffSum = vectorAdd(
            vectorSub(bestDiffSum, subtractedData[worseIndex]),
            subtractedData[betterIndex]
          );
          if(vectorNorm(newDiffSum) < vectorNorm(bestDiffSum)){
            bestDiffSum = newDiffSum;
            model[j][1] += 1;
            model[i][1] -= 1;
            localBetterIndexFound = (model[i][1] > 0);
            betterIndexOnceFound = true;
          }
        }
      }
      model.sort((a,b) => b[1] - a[1]);
    }
  }

  // 7) Frekansları normalize et
  for(let i = 0; i < model.length; i++){
    model[i][1] = model[i][1] / maxNumBestIndexes;
  }

  // 8) bestDistance hesapla (ilk 25 sütun)
  let sumVec = zeroVector(25);
  for(let idx of bestIndexes){
    for(let d = 0; d < 25; d++){
      sumVec[d] += subtractedData[idx][d];
    }
  }
  for(let d = 0; d < 25; d++){
    sumVec[d] /= maxNumBestIndexes;
  }
  let bestDistance = vectorNorm(sumVec);

  let finalModel = model.filter(m => m[1] > 0);
  return [bestDistance, finalModel];
}

// ----------------------------------------------------------------------------
// 4) Diğer yardımcı fonksiyonlar (getDataSubtracted, getHistogram, vb.)
// ----------------------------------------------------------------------------

/**
 * getDataSubtracted:
 * Python'daki "MULT = 0 if mul_ == 1 else mul_/8" kuralını uygular.
 * 26 sütun: (ilk 25 => difference, 25.sütun => distance * MULT)
 */
export function getDataSubtracted(mul_, sources_, target_) {
  let MULT = (mul_ === 1) ? 0 : (mul_ / 8);

  let subtracted = [];
  for(let i=0; i<sources_.length; i++){
    let row = new Array(26);
    let diff = zeroVector(25);
    for(let d=0; d<25; d++){
      diff[d] = sources_[i][d] - target_[d];
      row[d] = diff[d];
    }
    let dist = vectorNorm(diff);
    row[25] = dist * MULT;
    subtracted.push(row);
  }
  return subtracted;
}

/** getHistogram: 3 kez distance'siz + [0.5,1,2] ile getDataSubtracted(...) -> CansFastMonteCarlo */
export function getHistogram(sources_, target_) {
  let hist = new Array(sources_.length).fill(0);

  // A) distance kolonu olmadan 3 kez
  let subtractedNoDist = [];
  for(let i=0; i<sources_.length; i++){
    let row = new Array(25);
    for(let d=0; d<25; d++){
      row[d] = sources_[i][d] - target_[d];
    }
    subtractedNoDist.push(row);
  }

  for(let rep=0; rep<3; rep++){
    let [_, model] = CansFastMonteCarlo(subtractedNoDist, 50, 20, 0);
    for(let m of model){
      hist[m[0]] += m[1];
    }
  }

  // B) multiplier [0.5, 1, 2]
  let multipliers = [0.5, 1, 2];
  for(let mul_ of multipliers){
    let subDist = getDataSubtracted(mul_, sources_, target_);
    let [__, model2] = CansFastMonteCarlo(subDist, 50, 20, 0);
    for(let m of model2){
      hist[m[0]] += m[1];
    }
  }
  return hist;
}

/** getFrequentGroups: Kaynak label'ın ':' öncesini grup kabul, histogram değerine göre threshold=0.02 üstü gruplar. */
export function getFrequentGroups(sourceLabels_, histogram_) {
  let groups = {};
  let groupHistogram = {};
  let threshold = 0.02;

  for(let i=0; i<sourceLabels_.length; i++){
    let lbl = sourceLabels_[i];
    let grp = lbl.split(':')[0];
    if(!groups[grp]) {
      groups[grp] = [];
      groupHistogram[grp] = 0;
    }
    groups[grp].push(i);
    groupHistogram[grp] += histogram_[i];
  }

  let sortedGH = Object.entries(groupHistogram).sort((a,b) => b[1] - a[1]);
  let result = [];
  for(let [grp, freq] of sortedGH){
    if(freq > threshold) {
      result.push(groups[grp]);
    }
  }
  return result;
}

/** Verilen gruplardaki tüm index'leri tek diziye toplar. */
export function getSourceIndexes(groups) {
  let result = [];
  for(let g of groups){
    for(let idx of g){
      result.push(idx);
    }
  }
  return result;
}

/** Gruplar arasından rastgele yeni bir groupID bulur. (npop_ >= groupLength ise direk döner) */
export function getNewGroupID(currentGroupIDs, groupLength, npop_) {
  let n = randomInt(groupLength);
  if(groupLength <= npop_){
    return n;
  }
  while(currentGroupIDs.includes(n)){
    n = randomInt(groupLength);
  }
  return n;
}

/** Sonuçları grup bazında toplar. (label'ın ':' öncesi) */
export function aggregateResults(bestModel, sourceLabels) {
  let g = {};
  for(let [idx, perc] of bestModel){
    let lbl = sourceLabels[idx];
    let grp = lbl.split(':')[0];
    if(!g[grp]) g[grp] = 0;
    g[grp] += perc;
  }
  let sorted = Object.entries(g).sort((a,b) => b[1] - a[1]);
  return sorted;
}

/** Kaynak label'larında kaç farklı grup var, ona göre iterasyon sayısı (10,5,1) karar verir. */
export function getIterationNumber(sourceLabels_) {
  let set_ = new Set();
  for(let lbl of sourceLabels_){
    let grp = lbl.split(':')[0];
    set_.add(grp);
  }
  let len = set_.size;
  if(len > 500) return 10;
  else if(len > 100) return 5;
  else return 1;
}

// ----------------------------------------------------------------------------
// 5) reducedRegressionIteration ve reducedRegression (addDistCol_ eklendi)
// ----------------------------------------------------------------------------

/**
 * reducedRegressionIteration(npop_, sources_, sourceLabels_, target_, cycleMultiplier_, addDistCol_)
 * 1) histogram -> frequent groups
 * 2) en yüksek gruplardan npop_ kadar seç
 * 3) getSourceIndexes
 * 4) subtractedSources = getDataSubtracted(addDistCol_, sources_, target_)
 *    best fit'i bul (CansFastMonteCarlo 500,20)
 * 5) 20 döngü => her grubun yerini değiştirme denemesi (CansFastMonteCarlo 35*cycleMultiplier_, 5*cycleMultiplier_)
 * 6) final best (CansFastMonteCarlo 500,20)
 * 7) index map
 */
export function reducedRegressionIteration(npop_, sources_, sourceLabels_, target_, cycleMultiplier_, addDistCol_) {
  // 1) histogram
  let hist = getHistogram(sources_, target_);
  // 2) gruplar
  let groups = getFrequentGroups(sourceLabels_, hist);

  // 3) başlangıç
  let bestGroupIDs = [];
  let bestGroups = [];
  for(let i=0; i<npop_ && i<groups.length; i++){
    bestGroupIDs.push(i);
    bestGroups.push(groups[i]);
  }
  let bestSourceIndexes = getSourceIndexes(bestGroups);

  // 4) subtractedSources
  let subtractedSources = getDataSubtracted(addDistCol_, sources_, target_);
  let [bestFit, _m] = CansFastMonteCarlo(
    bestSourceIndexes.map(idx => subtractedSources[idx]),
    500, 20, 0
  );

  // 5) 20 döngü => her grubun yerini değiştirme
  let nCycles = 20;
  for(let cyc=0; cyc<nCycles; cyc++){
    for(let i=0; i<bestGroups.length; i++){
      let newGID = getNewGroupID(bestGroupIDs, groups.length, npop_);
      let newGs = bestGroups.slice();
      newGs[i] = groups[newGID];
      let newSrcIdx = getSourceIndexes(newGs);

      let [newFit, __model] = CansFastMonteCarlo(
        newSrcIdx.map(idx => subtractedSources[idx]),
        35*cycleMultiplier_, 
        5*cycleMultiplier_, 
        0
      );
      if(newFit < bestFit){
        bestFit = newFit;
        bestGroups = newGs;
        bestGroupIDs[i] = newGID;
        bestSourceIndexes = newSrcIdx;
      }
    }
  }

  // 6) final best
  let [finalFit, finalModel] = CansFastMonteCarlo(
    bestSourceIndexes.map(idx => subtractedSources[idx]),
    500, 20, 0
  );

  // 7) map
  for(let i=0; i<finalModel.length; i++){
    finalModel[i][0] = bestSourceIndexes[ finalModel[i][0] ];
  }
  return [finalFit, finalModel];
}

/**
 * reducedRegression(sources_, sourceLabels_, target_, npop_, cycleMultiplier_, addDistCol_)
 *  - npop_ == 0 => CansFastMonteCarlo(getDataSubtracted(addDistCol_, ...), 500,20)
 *  - aksi => reducedRegressionIteration(...) bir kaç kez
 */
export function reducedRegression(sources_, sourceLabels_, target_, npop_, cycleMultiplier_, addDistCol_) {
  if(npop_ === 0) {
    let subtractedSources = getDataSubtracted(addDistCol_, sources_, target_);
    return CansFastMonteCarlo(subtractedSources, 500, 20, 0);
  }
  else if(npop_ < 0){
    console.error("npop cannot be negative!");
    return [-1, []];
  }

  let bestFit = 1000;
  let bestModel = [];
  let firstFit = -1;
  let iteration = 0;
  let repeatedBefore = false;
  let nIter = getIterationNumber(sourceLabels_);

  while(iteration < nIter){
    let [fit, model] = reducedRegressionIteration(npop_, sources_, sourceLabels_, target_, cycleMultiplier_, addDistCol_);
    if(fit < bestFit){
      bestFit = fit;
      bestModel = model;
      if(firstFit === -1){
        firstFit = fit;
      }
    }
    // Erken durdurma
    if((firstFit - bestFit > firstFit*0.5 || bestFit < 0.001) && iteration >= nIter/2){
      break;
    }
    iteration++;
    if(iteration===nIter && nIter>1 && (firstFit - bestFit<0.0005) && !repeatedBefore){
      iteration=0;
      repeatedBefore=true;
    }
  }
  return [bestFit, bestModel];
}

// ----------------------------------------------------------------------------
// 6) Ek (opsiyonel) fonksiyonlar
// ----------------------------------------------------------------------------

/**
 * euclideanDistance
 * vec1 ve vec2'nin ilk elemanının label/name olduğu varsayılırsa
 * hesaplamayı [1..n] arasındaki kolonlarda yapar.
 */
export const euclideanDistance = (vec1, vec2) => {
  // vec1[0] ve vec2[0] isim/etiket -> bu nedenle hesaplamayı [1..n] arasındaki kolonlarda yapacağız
  const len = Math.min(vec1.length, vec2.length) - 1;
  let sumOfSquares = 0;

  for (let i = 1; i <= len; i++) {
    const diff = vec1[i] - vec2[i];
    sumOfSquares += diff * diff;
  }

  return Math.sqrt(sumOfSquares);
};
